// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* welcomenavbar.css */
.navbar {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 90px; /* Specify a height for the navbar */
  background-color: #0d6484; /* Background color, adjust as needed */
  width: 100%; /* Ensure the navbar spans the full width */
  position: relative; /* Changed from 'fixed' to 'relative' */
  top: 0; /* Align top edge at its normal position */
  left: 0; /* Align left edge at its normal position */
  z-index: 1000; /* Ensures the navbar is above other content */
}

.navbar-content {
  display: flex; /* Use Flexbox within this container as well */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Take up the full width of its parent */
}

.navbar-logo {
  height: 90px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/welcomenavbar.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,aAAa;EACb,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,YAAY,EAAE,oCAAoC;EAClD,yBAAyB,EAAE,uCAAuC;EAClE,WAAW,EAAE,2CAA2C;EACxD,kBAAkB,EAAE,uCAAuC;EAC3D,MAAM,EAAE,0CAA0C;EAClD,OAAO,EAAE,2CAA2C;EACpD,aAAa,EAAE,8CAA8C;AAC/D;;AAEA;EACE,aAAa,EAAE,8CAA8C;EAC7D,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,WAAW,EAAE,yCAAyC;AACxD;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["/* welcomenavbar.css */\n.navbar {\n  display: flex;\n  justify-content: center; /* Center horizontally */\n  align-items: center; /* Center vertically */\n  height: 90px; /* Specify a height for the navbar */\n  background-color: #0d6484; /* Background color, adjust as needed */\n  width: 100%; /* Ensure the navbar spans the full width */\n  position: relative; /* Changed from 'fixed' to 'relative' */\n  top: 0; /* Align top edge at its normal position */\n  left: 0; /* Align left edge at its normal position */\n  z-index: 1000; /* Ensures the navbar is above other content */\n}\n\n.navbar-content {\n  display: flex; /* Use Flexbox within this container as well */\n  justify-content: center; /* Center horizontally */\n  align-items: center; /* Center vertically */\n  width: 100%; /* Take up the full width of its parent */\n}\n\n.navbar-logo {\n  height: 90px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
