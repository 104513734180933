// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-wrapper {
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 3em;
}

input[type='text'].align-custom,
input[type='password'].align-custom {
  width: 100%;
}

label * {
  padding-top: 1em;
  padding-bottom: 1em;
}

button[type='submit'] {
  margin-top: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoginPriv.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".login-wrapper {\n  width: 50%;\n  margin: auto;\n  text-align: center;\n  padding-top: 3em;\n}\n\ninput[type='text'].align-custom,\ninput[type='password'].align-custom {\n  width: 100%;\n}\n\nlabel * {\n  padding-top: 1em;\n  padding-bottom: 1em;\n}\n\nbutton[type='submit'] {\n  margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
