// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensures modal is above other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px; /* Ensures modal is not too wide on larger screens */
}

.close {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: red;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  font-size: 20px;
  color: #333;
}

select,
input[type='number'] {
  font-size: 1.25rem;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Includes padding and border in width */
  transition: border-color 0.3s;
}

select:hover,
input[type='number']:hover,
select:focus,
input[type='number']:focus {
  border-color: #555; /* Darker border on hover/focus */
}
`, "",{"version":3,"sources":["webpack://./src/styles/transfer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,gCAAgC;EACtE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa,EAAE,yCAAyC;AAC1D;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,UAAU;EACV,gBAAgB,EAAE,oDAAoD;AACxE;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB,EAAE,yCAAyC;EACjE,6BAA6B;AAC/B;;AAEA;;;;EAIE,kBAAkB,EAAE,iCAAiC;AACvD","sourcesContent":[".modal {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000; /* Ensures modal is above other content */\n}\n\n.modal-content {\n  background: white;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  width: 90%;\n  max-width: 500px; /* Ensures modal is not too wide on larger screens */\n}\n\n.close {\n  float: right;\n  font-size: 28px;\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.close:hover {\n  color: red;\n}\n\nform {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\nlabel {\n  font-size: 20px;\n  color: #333;\n}\n\nselect,\ninput[type='number'] {\n  font-size: 1.25rem;\n  width: 100%;\n  padding: 8px;\n  margin-top: 5px;\n  border: 2px solid #ccc;\n  border-radius: 5px;\n  box-sizing: border-box; /* Includes padding and border in width */\n  transition: border-color 0.3s;\n}\n\nselect:hover,\ninput[type='number']:hover,\nselect:focus,\ninput[type='number']:focus {\n  border-color: #555; /* Darker border on hover/focus */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
